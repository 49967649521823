import React from 'react';
import {
  ChakraProvider,
  extendTheme
} from '@chakra-ui/react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import { STAFF_TOKEN_LOCAL_KEY } from 'config/const';

import { Index as LoginIndex } from 'views/login';

import { Index } from 'views/index';
import { Index as MyIndex } from 'views/my/index';

import { Index as StaffsIndex } from 'views/staffs/index';
import { Create as StaffsCreate } from 'views/staffs/create';
import { Show as StaffsShow } from 'views/staffs/show';

import { Index as MembersIndex } from 'views/members/index';
import { Create as MembersCreate } from 'views/members/create';
import { Show as MembersShow } from 'views/members/show';
import { Index as MembersImportIndex } from 'views/members/import';

import { Index as PaymentsIndex } from 'views/payments/index';
import { Index as PaymentsImportIndex } from 'views/payments//import';
import { Index as CheckLogsIndex } from 'views/check_logs/index';
import { Index as ExamLogsIndex } from 'views/exam_logs/index';

import { Index as WelcomesIndex } from 'views/welcomes/index';
import { Show as WelcomesShow } from 'views/welcomes/show';

import { Index as ChecksIndex } from 'views/checks/index';
import { Create as ChecksCreate } from 'views/checks/create';
import { Show as ChecksShow } from 'views/checks/show';

import { Index as DownloadsIndex } from 'views/downloads/index';
import { Create as DownloadsCreate } from 'views/downloads/create';
import { Show as DownloadsShow } from 'views/downloads/show';

import { Index as ReferencesIndex } from 'views/references/index';
import { Create as ReferencesCreate } from 'views/references/create';
import { Show as ReferencesShow } from 'views/references/show';

import { Index as SeminarsIndex } from 'views/seminars/index';
import { Create as SeminarsCreate } from 'views/seminars/create';
import { Show as SeminarsShow } from 'views/seminars/show';

const theme = extendTheme({
  colors: {
    base: {
      "50": "#f4f9f3",
      "100": "#d1e6ce",
      "200": "#a9d0a3",
      "300": "#78b56e",
      "400": "#5ba54f",
      "500": "#348f24",
      "600": "#147a03",
      "700": "#106302",
      "800": "#0e5302",
      "900": "#0a3c01"
    },
    sub : {
      "50": "#F2F2F2",
      "100": "#DBDBDB",
      "200": "#C4C4C4",
      "300": "#ADADAD",
      "400": "#969696",
      "500": "#808080",
      "600": "#666666",
      "700": "#4D4D4D",
      "800": "#333333",
      "900": "#1A1A1A"
    },
    // master: '#01007C',
    slave: '#3E4FA0',
    // sub:  '#F1F1F5',
    opposite: 'white',
    master: {
      "50": "#FCE8E9",
      "100": "#F8BFC2",
      "200": "#F3969A",
      "300": "#EE6D73",
      "400": "#E9444C",
      "500": "#E41B24",
      "600": "#B7151D",
      "700": "#891016",
      "800": "#5B0B0F",
      "900": "#2E0507"
    }
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginIndex />} />

          <Route path="/" element={<ProtectedRoute><Index /></ProtectedRoute>} />

          <Route path="/my" element={<ProtectedRoute><MyIndex /></ProtectedRoute>} />

          <Route path="/staffs" element={<ProtectedRoute><StaffsIndex /></ProtectedRoute>} />
          <Route path="/staffs/create" element={<ProtectedRoute><StaffsCreate /></ProtectedRoute>} />
          <Route path="/staffs/:id" element={<ProtectedRoute><StaffsShow /></ProtectedRoute>} />

          <Route path="/members" element={<ProtectedRoute><MembersIndex /></ProtectedRoute>} />
          <Route path="/members/create" element={<ProtectedRoute><MembersCreate /></ProtectedRoute>} />
          <Route path="/members/import" element={<ProtectedRoute><MembersImportIndex /></ProtectedRoute>} />
          <Route path="/members/:id" element={<ProtectedRoute><MembersShow /></ProtectedRoute>} />

          <Route path="/payments" element={<ProtectedRoute><PaymentsIndex /></ProtectedRoute>} />
          <Route path="/payments/import" element={<ProtectedRoute><PaymentsImportIndex /></ProtectedRoute>} />

          <Route path="/check_logs" element={<ProtectedRoute><CheckLogsIndex /></ProtectedRoute>} />
          <Route path="/exam_logs" element={<ProtectedRoute><ExamLogsIndex /></ProtectedRoute>} />

          <Route path="/welcomes" element={<ProtectedRoute><WelcomesIndex /></ProtectedRoute>} />
          <Route path="/welcomes/:id" element={<ProtectedRoute><WelcomesShow /></ProtectedRoute>} />

          <Route path="/checks" element={<ProtectedRoute><ChecksIndex /></ProtectedRoute>} />
          <Route path="/checks/create" element={<ProtectedRoute><ChecksCreate /></ProtectedRoute>} />
          <Route path="/checks/:id" element={<ProtectedRoute><ChecksShow /></ProtectedRoute>} />

          <Route path="/downloads" element={<ProtectedRoute><DownloadsIndex /></ProtectedRoute>} />
          <Route path="/downloads/create" element={<ProtectedRoute><DownloadsCreate /></ProtectedRoute>} />
          <Route path="/downloads/:id" element={<ProtectedRoute><DownloadsShow /></ProtectedRoute>} />

          <Route path="/references" element={<ProtectedRoute><ReferencesIndex /></ProtectedRoute>} />
          <Route path="/references/create" element={<ProtectedRoute><ReferencesCreate /></ProtectedRoute>} />
          <Route path="/references/:id" element={<ProtectedRoute><ReferencesShow /></ProtectedRoute>} />

          <Route path="/seminars" element={<ProtectedRoute><SeminarsIndex /></ProtectedRoute>} />
          <Route path="/seminars/create" element={<ProtectedRoute><SeminarsCreate /></ProtectedRoute>} />
          <Route path="/seminars/:id" element={<ProtectedRoute><SeminarsShow /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  if (localStorage.getItem(STAFF_TOKEN_LOCAL_KEY)) {
    return children;
  }

  return <Navigate to="/login" />;
};

export default App;
