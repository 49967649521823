import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  HStack,
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Spacer,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  FormControl,
  FormLabel,
  SimpleGrid,
  Input,
  Icon,
  Text,
  Button,
} from '@chakra-ui/react';
import {
  FiPlus,
  FiUploadCloud,
  FiDownloadCloud,
  FiShare,
} from 'react-icons/fi';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Select from 'react-select'

import { Enums, GetEnumsName, DataTablePaginationComponentOptions } from 'config/const';
import { Fetch } from 'utils/fetch';
import { Layout } from 'views/components/layout';
import { Index as Response } from 'interfaces/api/members/index';

const searchLevelOptions = [
  { value: 1, label: '一般相続診断士' },
  { value: 2, label: '上級相続診断士' },
];

const searchAutoRenewOptions = [
  { value: 1, label: '有効' },
  { value: 2, label: '無効' },
  { value: 3, label: '開始申請中' },
  { value: 4, label: '停止申請中' },
];

export const Index = () => {
  const navigate = useNavigate();
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [length, setLength] = React.useState(50);
  const [page, setPage] = React.useState(1);
  const [searchWord, setSearchWord] = React.useState('');
  const [searchApprovalPeriodFrom, setSearchApprovalPeriodFrom] = React.useState('');
  const [searchApprovalPeriodTo, setSearchApprovalPeriodTo] = React.useState('');
  const [searchApprovalAtFrom, setSearchApprovalAtFrom] = React.useState('');
  const [searchApprovalAtTo, setSearchApprovalAtTo] = React.useState('');
  const [searchUpdateAtFrom, setSearchUpdateAtFrom] = React.useState('');
  const [searchUpdateAtTo, setSearchUpdateAtTo] = React.useState('');
  const [searchLevel, setSearchLevel] = React.useState<string | undefined>(undefined);
  const [searchAutoRenew, setSearchAutoRenew] = React.useState<string | undefined>(undefined);
  const [search, setSearch] = React.useState<string | undefined>(undefined);
  const [cardWidth, setCardWidth] = React.useState<number | undefined>(undefined);
  const selectInputRef = React.useRef<any>();

  const columns = [
    {
      name: '#',
      selector: (row: any) => <HStack>
          <Icon
            as={FiShare}
            w={5}
            h={5}
            color='base.700'
            onClick={() => navigate(`/members/${row.id}`)}
            sx={{ cursor: 'pointer' }}
          />
          <Text>{row.id}</Text>
        </HStack>,
    },
    {
      name: '会員名',
      selector: (row: any) => row.name
    },
    {
      name: 'レベル',
      selector: (row: any) => GetEnumsName(Enums['members']['level'], row.level)
    },
    {
      name: '住所区分',
      selector: (row: any) => GetEnumsName(Enums['members']['address_type'], row.addressType)
    },
    {
      name: '会社',
      selector: (row: any) => row.company,
      //grow: 2
    },
    {
      name: '認定有効期限',
      selector: (row: any) => row.approvalPeriod ? row.approvalPeriod : '-',
    },
    {
      name: 'メールアドレス',
      selector: (row: any) => row.email,
      //grow: 3
    },
    {
      name: '電話番号',
      selector: (row: any) => row.tel,
      //grow: 2
    },
    {
      name: '認定日',
      selector: (row: any) => row.approvalAt ? row.approvalAt : '-',
    },
    {
      name: '自動課金',
      selector: (row: any) => GetEnumsName(Enums['members']['auto_renew'], row.autoRenew)
    },
    {
      name: '更新日時',
      selector: (row: any) => row.updateAt ? moment.unix(row.updateAt).format('YYYY/MM/DD HH:mm') : '-',
      //center: true,
      //grow: 2
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLength(newPerPage);
  };

  const downloadExport = () => {
    Fetch.download(`members/export${search ? `?${search}` : ''}`, '会員.csv');
  };

  const handleSearchLevelChange = (e: any) => {
    const levels: Array<number> = [];

    e.forEach((option: any) => {
      levels.push(option.value);
    });

    const level = levels.join(',');

    setSearchLevel(level);
  };

  const handleSearchAutoRenewChange = (e: any) => {
    const autoRenews: Array<number> = [];

    e.forEach((option: any) => {
      autoRenews.push(option.value);
    });

    const autoRenew = autoRenews.join(',');

    setSearchAutoRenew(autoRenew);
  };

  const handleSearchReset = () => {
    setPage(1);
    setSearchWord('');
    setSearchApprovalPeriodFrom('');
    setSearchApprovalPeriodTo('');
    setSearchApprovalAtFrom('');
    setSearchApprovalAtTo('');
    setSearchUpdateAtFrom('');
    setSearchUpdateAtTo('');
    setSearchLevel(undefined);
    setSearchAutoRenew(undefined);
    setSearch(undefined);

    if (selectInputRef.current) {
      selectInputRef.current.clearValue();
    }
  };

  const handleSearchSubmit = () => {
    setPage(1);
    const searchs = [];

    if (searchWord) {
      searchs.push(`word=${searchWord}`);
    }

    if (searchApprovalPeriodFrom) {
      searchs.push(`approvalPeriodFrom=${searchApprovalPeriodFrom}`);
    }

    if (searchApprovalPeriodTo) {
      searchs.push(`approvalPeriodTo=${searchApprovalPeriodTo}`);
    }

    if (searchApprovalAtFrom) {
      searchs.push(`approvalAtFrom=${searchApprovalAtFrom}`);
    }

    if (searchApprovalAtTo) {
      searchs.push(`approvalAtTo=${searchApprovalAtTo}`);
    }

    if (searchUpdateAtFrom) {
      searchs.push(`updateAtFrom=${moment(searchUpdateAtFrom).startOf('day').unix()}`);
    }

    if (searchUpdateAtTo) {
      searchs.push(`updateAtTo=${moment(searchUpdateAtTo).startOf('day').unix()}`);
    }

    if (searchLevel && searchLevel.length > 0) {
      searchs.push(`level=${searchLevel}`);
    }

    if (searchAutoRenew && searchAutoRenew.length > 0) {
      searchs.push(`autoRenew=${searchAutoRenew}`);
    }

    if (searchs.length > 0) {
      setSearch(searchs.join('&'));
    } else {
      setSearch(undefined);
    }
  };

  React.useEffect(() => {
    (async() => {
      await Fetch.get(`members?length=${length}&page=${page}${search ? `&${search}` : ''}`).then(res => {
        setLoading(false);

        if (res?.error) {
          return false;
        }

        setResponse(res);
        setCardWidth(document.getElementById('contentHeader')?.getBoundingClientRect()?.width);
      });
    })()
  }, [length, page, search]);

  return (
    <Layout
      currentNavi='members'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
        maxW={cardWidth ? cardWidth : 'full'}
      >
        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <HStack>
              <Heading size='md'>会員一覧</Heading>
              <Spacer />
              <IconButton
                isRound={true}
                variant='solid'
                colorScheme='green'
                aria-label='create'
                fontSize={20}
                icon={<FiPlus />}
                as={Link}
                to='/members/create'
              />

              <IconButton
                isRound={true}
                variant='solid'
                colorScheme='green'
                aria-label='import'
                fontSize={20}
                icon={<FiUploadCloud />}
                as={Link}
                to='/members/import'
              />

              <IconButton
                isRound={true}
                variant='solid'
                colorScheme='green'
                aria-label='download'
                fontSize={20}
                icon={<FiDownloadCloud />}
                onClick={downloadExport}
                isDisabled={!response || response.members.length === 0}
              />
            </HStack>
          </CardHeader>
          <CardBody>

            <Accordion
              allowToggle
              mb={5}
            >
              <AccordionItem>
                <h2>
                  <AccordionButton _expanded={{ bg: 'base.700', color: 'white' }}>
                    <Box as="span" flex='1' textAlign='left'>
                      詳細検索
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <FormControl
                    my={3}
                  >
                    <FormLabel>会員レベル</FormLabel>
                    <Select
                      ref={selectInputRef}
                      isMulti
                      options={searchLevelOptions}
                      placeholder='会員レベル'
                      onChange={handleSearchLevelChange}
                    />
                  </FormControl>

                  <FormControl
                    my={3}
                  >
                    <FormLabel>自動課金</FormLabel>
                    <Select
                      ref={selectInputRef}
                      isMulti
                      options={searchAutoRenewOptions}
                      placeholder='自動課金'
                      onChange={handleSearchAutoRenewChange}
                    />
                  </FormControl>

                  <FormControl
                    my={3}
                  >
                    <FormLabel>検索キーワード</FormLabel>
                    <Input
                      id='searchWord'
                      name='searchWord'
                      type='text'
                      placeholder='検索キーワード'
                      onChange={(e: any) => setSearchWord(e.target.value)}
                      value={searchWord}
                    />
                  </FormControl>

                  <FormControl
                    my={3}
                  >
                    <FormLabel>認定有効期限</FormLabel>
                    <SimpleGrid
                      columns={2}
                      spacing={10}
                    >
                      <Input
                        id='searchApprovalPeriodFrom'
                        name='searchApprovalPeriodFrom'
                        type='date'
                        placeholder='認定有効期限'
                        onChange={(e: any) => setSearchApprovalPeriodFrom(e.target.value)}
                        value={searchApprovalPeriodFrom}
                      />

                      <Input
                        id='searchApprovalPeriodTo'
                        name='searchApprovalPeriodTo'
                        type='date'
                        placeholder='認定有効期限'
                        onChange={(e: any) => setSearchApprovalPeriodTo(e.target.value)}
                        value={searchApprovalPeriodTo}
                      />
                    </SimpleGrid>
                  </FormControl>

                  <FormControl
                    my={3}
                  >
                    <FormLabel>認定日</FormLabel>
                    <SimpleGrid
                      columns={2}
                      spacing={10}
                    >
                      <Input
                        id='searchApprovalAtFrom'
                        name='searchApprovalAtFrom'
                        type='date'
                        placeholder='認定日'
                        onChange={(e: any) => setSearchApprovalAtFrom(e.target.value)}
                        value={searchApprovalAtFrom}
                      />

                      <Input
                        id='searchApprovalAtTo'
                        name='searchApprovalAtTo'
                        type='date'
                        placeholder='認定日'
                        onChange={(e: any) => setSearchApprovalAtTo(e.target.value)}
                        value={searchApprovalAtTo}
                      />
                    </SimpleGrid>
                  </FormControl>

                  <FormControl
                    my={3}
                  >
                    <FormLabel>更新日</FormLabel>
                    <SimpleGrid
                      columns={2}
                      spacing={10}
                    >
                      <Input
                        id='searchUpdateAtFrom'
                        name='searchUpdateAtFrom'
                        type='date'
                        placeholder='更新日'
                        onChange={(e: any) => setSearchUpdateAtFrom(e.target.value)}
                        value={searchUpdateAtFrom}
                      />

                      <Input
                        id='searchUpdateAtTo'
                        name='searchUpdateAtTo'
                        type='date'
                        placeholder='更新日'
                        onChange={(e: any) => setSearchUpdateAtTo(e.target.value)}
                        value={searchUpdateAtTo}
                      />
                    </SimpleGrid>
                  </FormControl>

                  <SimpleGrid
                    my={5}
                    columns={2}
                    spacing={5}
                  >
                    <Button onClick={handleSearchReset}>リセット</Button>
                    <Button
                      bg="base.700"
                      color="white"
                      colorScheme="green"
                      onClick={handleSearchSubmit}
                    >検索</Button>
                  </SimpleGrid>

                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            {(response && response.members.length > 0) ?
              <DataTable
                columns={columns}
                data={response?.members}
                highlightOnHover
                pagination
                paginationServer
                fixedHeader
                fixedHeaderScrollHeight="300px"
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationTotalRows={response?.countTotal}
                paginationComponentOptions={DataTablePaginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                paginationPerPage={length}
              />
            :
              <>データがありません</>
            }
          </CardBody>
        </Card>
      </VStack>
    </Layout>
  );
};
