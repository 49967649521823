import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';

import { Enums, GetEnumsName } from 'config/const';
import { Fetch } from 'utils/fetch';
import { Layout } from 'views/components/layout';
import { InlineEditor } from 'views/components/inline_editor';
import { Show as Response } from 'interfaces/api/members/show';

export const Show = () => {
  const { id } = useParams<Record<string, string | undefined>>()
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    Fetch.get(`members/${id}`).then(res => {
      setLoading(false);

      if (res?.error) {
        return false;
      }

      setResponse(res);
    });
  }, [id]);

  return (
    <Layout
      currentNavi='members'
      loading={loading}
      name={response?.my?.name}
      avatar={response?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
      >

        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <Heading size='md'>{response?.member?.name ? response.member.name : '会員詳細'}</Heading>
            <Divider mt={3} />
          </CardHeader>
          <CardBody>
            {response &&
              <>
                <Heading
                  as='h3'
                  size='md'
                  my={3}
                >基本情報</Heading>
                <Divider />

                <InlineEditor
                  name='id'
                  value={response?.member?.id}
                  title='会員ID'
                  isReadOnly={true}
                />

                <InlineEditor
                  name='name'
                  value={response?.member?.name}
                  title='会員名'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='level'
                  value={response?.member?.level}
                  title='レベル'
                  isRequired={true}
                  url={`members/${id}`}
                  type='enums'
                  enums={Enums['members']['level']}
                />

                <InlineEditor
                  name='kana'
                  value={response?.member?.kana}
                  title='フリガナ'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='approvalPeriod'
                  value={response?.member?.approvalPeriod ? moment(response?.member?.approvalPeriod).format('YYYY/MM/DD') : '-'}
                  title='認定有効期限'
                  isReadOnly={true}
                />

                <InlineEditor
                  name='tel'
                  value={response?.member?.tel}
                  title='電話番号'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='email'
                  value={response?.member?.email}
                  title='メールアドレス'
                  url={`members/${id}`}
                  type='email'
                />

                <InlineEditor
                  name='birth'
                  value={response?.member?.birth}
                  title='生年月日'
                  isReadOnly={true}
                />

                <InlineEditor
                  name='approval_at'
                  value={response?.member?.approvalAt ? moment(response?.member?.approvalAt).format('YYYY/MM/DD') : '-'}
                  title='認定日'
                  url={`members/${id}`}
                  type='date'
                />

                <Heading
                  as='h3'
                  size='md'
                  my={3}
                >会社情報</Heading>
                <Divider />

                <InlineEditor
                  name='company'
                  value={response?.member?.company}
                  title='会社'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='department'
                  value={response?.member?.department}
                  title='部署名'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='position'
                  value={response?.member?.position}
                  title='役職'
                  url={`members/${id}`}
                />

                <Heading
                  as='h3'
                  size='md'
                  my={3}
                >発送先情報</Heading>
                <Divider />

                <InlineEditor
                  name='shipping_zip'
                  value={response?.member?.shippingZip}
                  title='郵便番号'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='shipping_pref'
                  value={response?.member?.shippingPref}
                  title='都道府県'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='shipping_address1'
                  value={response?.member?.shippingAddress1}
                  title='住所1'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='shipping_address2'
                  value={response?.member?.shippingAddress2}
                  title='住所2'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='shipping_address3'
                  value={response?.member?.shippingAddress3}
                  title='住所3'
                  url={`members/${id}`}
                />

                <Heading
                  as='h3'
                  size='md'
                  my={3}
                >住所情報</Heading>
                <Divider />

                <InlineEditor
                  name='address_type'
                  value={response?.member?.addressType}
                  title='住所区分'
                  isRequired={true}
                  url={`members/${id}`}
                  type='enums'
                  enums={Enums['members']['address_type']}
                />

                <InlineEditor
                  name='zip'
                  value={response?.member?.zip}
                  title='郵便番号'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='pref'
                  value={response?.member?.pref}
                  title='都道府県'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='address1'
                  value={response?.member?.address1}
                  title='住所1'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='address2'
                  value={response?.member?.address2}
                  title='住所2'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='address3'
                  value={response?.member?.address3}
                  title='住所3'
                  url={`members/${id}`}
                />

                <InlineEditor
                  name='createAt'
                  value={response?.member?.createAt ? moment.unix(response?.member?.createAt).format('YYYY/MM/DD HH:mm') : '-'}
                  title='作成日時'
                  isReadOnly={true}
                />

                <InlineEditor
                  name='approvalAt'
                  value={response?.member?.updateAt ? moment.unix(response?.member?.updateAt).format('YYYY/MM/DD HH:mm') : '-'}
                  title='更新日時'
                  isReadOnly={true}
                />
              </>
            }

            <Heading
              as='h3'
              size='md'
              my={3}
            >決済履歴</Heading>
            <Divider />

            <TableContainer mt={3}>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>決済方法</Th>
                    <Th isNumeric>決済金額</Th>
                    <Th>課金番号</Th>
                    <Th isNumeric>決済日時</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {(response?.payments && response?.payments.length > 0) &&
                  response.payments.map(payment => (
                    <Tr key={payment.id}>
                      <Td>{payment.id}</Td>
                      <Td>{GetEnumsName(Enums['payments']['type'], payment.type)}</Td>
                      <Td isNumeric>¥<NumericLabel>{payment.amount}</NumericLabel></Td>
                      <Td>{payment.gid}</Td>
                      <Td isNumeric>{payment.updateAt ? moment.unix(payment.updateAt).format('YYYY/MM/DD HH:mm') : '-'}</Td>
                    </Tr>
                  ))
                }
                </Tbody>
              </Table>
            </TableContainer>

          </CardBody>

          <CardFooter>
            <Button
              w='full'
              as={Link}
              to='/members'
            >
              一覧へ
            </Button>
          </CardFooter>
        </Card>

      </VStack>
    </Layout>
  );
};
