import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  HStack,
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Spacer,
  IconButton,
  Button,
  FormLabel,
  SimpleGrid,
  Input,
  Icon,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import {
  FiList,
  FiFileText,
} from 'react-icons/fi';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';

import { Fetch } from 'utils/fetch';
import { useToast } from 'utils/toast';
import { Layout } from 'views/components/layout';
import { Index as MyResponse } from 'interfaces/api/my';
import { Import as ImportResponse, ImportCompleteRequest } from 'interfaces/api/payments/import';
import { CsvPayment } from 'interfaces/models/payment';

export const Index = () => {
  const navigate = useNavigate();
  const { sendToast } = useToast();
  const [myResponse, setMyResponse] = React.useState<MyResponse | undefined>(undefined);
  const [importResponse, setImportResponse] = React.useState<ImportResponse | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [cardWidth, setCardWidth] = React.useState<number | undefined>(undefined);
  const [previewFile, setPreviewFile] = React.useState({ filename: '', raw: '' });
  const [importCompleteRequest, setImportCompleteRequest] = React.useState<Array<ImportCompleteRequest> | undefined>(undefined);

  const changeFile = (e: any) => {
    e.preventDefault();

    if (e.target.files.length) {
      setPreviewFile({
        filename: e.target.files[0].name,
        raw: e.target.files[0]
      });
    }
  };

  const handleUpload = (e: any) => {
    e.preventDefault();

    if (!previewFile || !previewFile.filename) {
      return false;
    }

    let csv = new FormData();
    csv.append('file', previewFile.raw);

    Fetch.post('payments/import', csv).then(res => {
      if (res?.error) {
        sendToast({title: 'アップロード失敗しました', description: 'ファイルを確認してください', status: 'error'});
        return false;
      }

      setImportResponse(res);

      const request = [] as Array<ImportCompleteRequest>;
      res.payments.map((payment: CsvPayment) => {
        request.push({
          memberID: payment.memberID,
          amount: payment.amount,
          approvalPeriod: payment.approvalPeriod,
          createAt: payment.createAt
        });
      });

      setImportCompleteRequest(request);
    });
  };

  const handleUpdate = (e: any) => {
    if (!importCompleteRequest) {
      return false;
    }

    Fetch.post('payments/import/complete', importCompleteRequest).then(res => {
      if (res?.error) {
        sendToast({title: 'アップロード失敗しました', description: 'ファイルを確認してください', status: 'error'});
        return false;
      }

      navigate('/payments');
    });
  };


  React.useEffect(() => {
    Fetch.get(`my`).then(res => {
      setLoading(false);

      if (res?.error) {
        return false;
      }

      setMyResponse(res);
      setCardWidth(document.getElementById('contentHeader')?.getBoundingClientRect()?.width);
    });
  }, []);

  return (
    <Layout
      currentNavi='payments'
      loading={loading}
      name={myResponse?.my?.name}
      avatar={myResponse?.my?.avatar}
    >
      <VStack
        w='full'
        h='full'
        p={3}
        maxW={cardWidth ? cardWidth : 'full'}
      >
        <Card
          w='full'
          h='full'
        >
          <CardHeader>
            <HStack>
              <Heading size='md'>決済履歴 (PBT 決済のみ) CSVインポート</Heading>
              <Spacer />
              <IconButton
                isRound={true}
                variant='solid'
                colorScheme='green'
                aria-label='list'
                fontSize={20}
                icon={<FiList />}
                as={Link}
                to='/payments'
              />
            </HStack>
          </CardHeader>
          <CardBody>
            <a href='/static/templates/template_payments.csv' download='決済履歴テンプレート.csv'>
              <Text color='base.700'>決済履歴CSVテンプレートダウンロード</Text>
            </a>

            {(importResponse?.payments && importResponse.payments.length > 0) ?
            <>
            <TableContainer mt={5}>
              <Table variant='simple'>
                <TableCaption>インポート決済履歴一覧</TableCaption>
                <Thead>
                  <Tr>
                    <Th>会員ID</Th>
                    <Th>会員名</Th>
                    <Th>決済方法</Th>
                    <Th>決済金額</Th>
                    <Th>認定有効期限</Th>
                    <Th>決済日時</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {importResponse.payments.map((payment: CsvPayment, index: number) => (
                    <>
                    <Tr key={index}>
                      <Td>{payment.memberID}</Td>
                      <Td>{payment.memberName}</Td>
                      <Td>PBT 決済</Td>
                      <Td><NumericLabel>{payment.amount}</NumericLabel> 円</Td>
                      <Td>{payment.approvalPeriod}</Td>
                      <Td>{moment.unix(payment.createAt).format('YYYY/MM/DD HH:mm:ss')}</Td>
                    </Tr>
                    </>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>

            <SimpleGrid
                mt={5}
                columns={2}
                spacing={10}
                w='full'
              >
                <Button
                  as={Link}
                  to='/payments'
                >
                  キャンセル
                </Button>
                <Button
                  colorScheme='base'
                  onClick={handleUpdate}
                >
                  更新する
                </Button>
              </SimpleGrid>
            </>
            :
              <>
              <FormLabel
                mt={5}
              >
                <VStack
                  w='full'
                  h={150}
                  spacing={5}
                  p={5}
                  borderWidth={1}
                  borderColor='base.500'
                  justifyItems='center'
                >
                  {previewFile.filename ?
                    <Text fontSize='xl'>{previewFile.filename}</Text>
                  :
                    <Icon
                      as={FiFileText}
                      w={20}
                      h={20}
                    />
                  }
                  <Text>ここをクリックしてCSVファイルをアップロードしてください</Text>
                </VStack>
                <Input
                  sx={{ display: 'none' }}
                  type='file'
                  accept='text/csv'
                  onChange={changeFile}
                />
              </FormLabel>

              <SimpleGrid
                mt={5}
                columns={2}
                spacing={10}
                w='full'
              >
                <Button
                  as={Link}
                  to='/payments'
                >
                  キャンセル
                </Button>
                <Button
                  colorScheme='base'
                  isDisabled={!previewFile.filename}
                  onClick={handleUpload}
                >
                  確認する
                </Button>
              </SimpleGrid>
              </>
            }
          </CardBody>
        </Card>
      </VStack>
    </Layout>
  );
};
